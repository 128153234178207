import React, {useState, useRef, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import * as moment from 'moment';

import { Const } from '../../utils/variables';

import './chatIa.css';
import {IconAssets, ImageAssets} from '../../utils/ImageAssets';

import Writer from '../../components/writer/writer.component';
import Message from '../../components/message/message.component';
import {getVideocallUuid, userStartWriting, userEndWriting} from '../../services/chat.service';

import {getChannelMessages} from '../../services/chat.service';
import { Crypt } from 'hybrid-crypto-js';
import Avatar from '../../components/avatar/avatar.component';
import { createMessage, readAllIaMessages } from '../../services/ia.service';
import { sendUserIaMessage } from '../../services/chat.service';
import { createIAChat } from '../../services/chat.service';


import { getCoach } from '../../services/data.service';

const ChatIa = props => {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [coach, setCoach] = useState(null);
    const [showWriter, setShowWriter] = useState(true)
    const [isWriting, setIsWriting] = useState(false)


    const [idChat, setIdChat] = useState(null);

    const [messages, setMessages] = useState([]);
    const [filePreview, setFilePreview] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [actualConversation, setActualConversation] = useState();
    const [fileSended, setFileSended] = useState(false);


    const inputRef = useRef(null);
    //const socketRef = useRef(null);
    const scrollRef = useRef(null);

    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    useEffect(() => {
            let idNewChat = localStorage.getItem('idNewChat')
            console.log('idNewChat', idNewChat)
            setUser(localStorage.getItem('USER'))
            setCoach(location.state.coach)
            getCoachProfile()
        if (location.state.isNew && (idNewChat === null || idNewChat === 'null')) {
            setIsWriting(true)
            createIAChat(location.state.coach.id, location.state.thread_id, navigator.language, location.state.speciality).then(newChat => {
                localStorage.setItem('idNewChat', newChat.data.id)
                console.log('newChat', newChat)
                setIdChat(newChat.data.id)
                getMessagesChannel(newChat.data.id)
                setIsWriting(false)
            }).catch(err => {
                console.log('No se ha podido crear el nuevo chat', err)
            })
        } else {
            readAllIaMessages(location.state.idChat ? location.state.idChat : Number(idNewChat)).then(res => {
            }).catch(err => {
                console.log('No se han podido leer los mensajes')
            })
            //Hace que se vea el input para enviar el mensaje segun desde la vista que vengas
            setShowWriter(location.state.writer);
            setIdChat(location.state.idChat ? location.state.idChat : Number(idNewChat))
            getMessagesChannel(location.state.idChat ? location.state.idChat : Number(idNewChat))
            localStorage.setItem('chatSelected', JSON.stringify({chatId: location.state.idChat, coachId: location.state.coach.id}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCoachProfile = () => {
        getCoach(location.state.coach.id).then(res => {
            setCoach(res.data);
            //console.log('coach recogido con exito', res.data);
        }).catch(err => {
            //console.log('Error al recoger el coach', err)
        })
    }

    function handleVisibilityChange() {
        if (document.hidden) {
        } else {
          window.location.reload();
        }
      }

    //Recoge los mensajes de una conversacion al entrar
    const getMessagesChannel = (id) => {
        getChannelMessages(id).then(res => {
            setMessages(res.data.Messages.reverse());
            setActualConversation(res.data);
        }).catch(err => {
            //console.log('ERROR al recoger los mensajes', err);
        });
    }

    // Envia un nuevo mensaje
    const sendNewMessage = async (value) => {
        let messageOfInput = value.current.value
        // Si existe mensaje de texto o archvio
        if (value.current.value !== '') {
            //Manda el mensaje a la IA para que la IA cree su respuesta
            createMessage(value.current.value, location.state.thread_id).then(async resCreateMessage => {

                //Creamos el objeto del mensaje para añadirlo al array
                let arrayMessages = messages
                let newUserMessage = {
                    channel_id: idChat,
                    content: messageOfInput,
                    createdAt: new Date(),
                    id: arrayMessages.length > 0 ? arrayMessages[0].id + 1 : 0,
                    isSender: true,
                    user_id: user.id,
                    is_ia: true,
                    file: null,
                    fileType: null,
                    is_visible: true
                  }

                  let newMessage = '';
                    // Genera la instacia de CRYPT para encriptar el mensaje
                    let crypt = new Crypt();
                    let messageEncrypted;
                    // Recoge la clave publica de localstorage para encriptar el mensaje
                    let publicKeyAuthor = await localStorage.getItem('publickey');
                    // Encripta el mensaje con la clave publica dle usuario y del coach
                    messageEncrypted = await crypt.encrypt([publicKeyAuthor, actualConversation.channelUsers.coach.public_key], newMessage === '' ? messageOfInput : newMessage);

                    //Añadimos el nuevo mensaje al array sin encriptar
                    arrayMessages = [newUserMessage, ...arrayMessages]
                    setMessages(arrayMessages)
                    //Mostramos al user como que la IA esta escribiendo
                    setIsWriting(true)

                    sendUserIaMessage({}, idChat, user.id, null, messageEncrypted, messageOfInput).then(res => {
                        readAllIaMessages(idChat).then(res => {

                        }).catch(err => {
                            console.log('No se han podido leer los mensajes')
                        })
                        
                        let arrayMessages2 = messages
                        let newIAMessage = {
                            channel_id: idChat,
                            content: res.data,
                            createdAt: new Date(),
                            id: arrayMessages.length > 0 ? arrayMessages[0].id + 1 : 0,
                            isSender: true,
                            user_id: coach.id,
                            is_ia: true,
                            file: null,
                            fileType: null,
                            is_visible: true
                        }

                        //Añadimos el nuevo mensaje al array sin encriptar
                        arrayMessages2 = [newIAMessage, ...arrayMessages]
                        setMessages(arrayMessages2)

                        setIsWriting(false)
                    })
            }).catch(err => {
                console.log('err ia message', err)
            })
        }
    }


    // Maneja los archivos abiertos para poder mostrarlos y enviarlos
    const getFileObject = (value) => {
        let file = document.getElementById('inputFile').files[0];
        let type = file.type.split('/');
        //console.log('TYPE', type)
        switch (type[0]) {
            case 'image':
                setTypeData('image');
            break;
            case 'video':
                setTypeData('video');
            break;
            case 'application':
                setTypeData('document');
            break;
            default:
                setTypeData('image');
            break;
        }
        //setFile(value.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(value.target.files[0]);
        reader.onload = () => {
            setFilePreview(reader.result);
        }
    }

    // ENtra a una videollamada
    const enterVideoCall = async (uuidSession) => {
        // LLama al servidor para recoger el id de la videollamada a la que se va a entrar
        await getVideocallUuid(idChat).then(async resp => {
            // Recoge el token de localstorage
            let token = await localStorage.getItem('token');
            // Abre una nueva ventana con el videochat (Se abre de esta manera para que ningun navegador bloque la ventana emergente)
            const a = document.createElement("a")
            a.href = `https://${Const.videoURL}/sfu/${resp.data}/${token}`
            a.target = "_blank"
            a.rel = "noopener"
            a.click()
        }).catch(err => {
            console.log('Error al recoger el id de la sesion', err);
        })
    }

    //Envia al server si el usuario esta escribiendo para mostrarselo al otro usuario
    const userIsWriting = (value) => {
        if (value) {
            userStartWriting(idChat, coach.id).then(res => {
            }).catch(err => {
                //console.log('Error al empezar a escribir')
            })
        } else {
            userEndWriting(idChat, coach.id).then(res => {
            }).catch(err => {
                //console.log('El usuario no ha podido dejar de escribir', err)
            })
        }
    }


    return (
        <>
        <div className={'containerBlocksChat'}>
            <input id={'inputFile'} ref={inputRef} hidden={true} type={'file'} onChange={value => getFileObject(value)}/>


            <div className={'containerChatChat'}>
                {coach &&
                    <div className={'headerChat'}>
                    
                        <Avatar
                        style={{marginLeft: 40}}
                        image={coach.image === null ? ImageAssets.userTest : `${coach.image}`}
                        status={coach.status}
                        />
                        <div>
                            <p className={'nameUserChat'}  onClick={() => {
                                //setShowUserCard(!showUserCard);
                                //setShowMenu(false);
                            }}>{`${coach.name} ${coach.lastname}`}</p>

                            {isWriting ?
                                <p className={'textWritingChat'}>Escribiendo...</p>
                                :
                                <p className={'textNoWritingChat'}>a</p>
                            }
                        </div>
                    </div>
                }
                <div className={'containerChat'}>
                    {typeData === null ?
                        <div id={'containerMessages'} ref={scrollRef} className={'containerMessagesChat'} onLoad={() => console.log('')}>
                            {messages.length > 0 &&
                            (messages.map(message => {
                                    if (message.is_visible) {
                                        return (
                                            <Message
                                                sender={Number(message.user_id) !== Number(coach.id)}
                                                message={message.content}
                                                idChat={message.channel_id}
                                                type={message.message_type}
                                                time={moment(message.createdAt).format('HH:mm')}
                                                file={message.file}
                                                pressFile={value => setFilePreview(value)}
                                                typeFile={value => setTypeData(value)}
                                                isSended={value => setFileSended(value)}
                                                isIa={message.is_ia}
                                                press={() => {
                                                    enterVideoCall(message.videocall_uuid)
                                                }}
                                            />
                                        )
                                    } else {
                                        return null
                                    }
                                    
                                })
                            )
                            }
                        </div>
                        :
                        <div className={'containerFilesChat'}>
                            {typeData === 'image' &&
                                <>
                                    <img className={'iconCloseChat'} alt={''} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        //setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <img className={'imageFile'} alt={''} src={filePreview}/>
                                </>
                            }
                            {typeData === 'video' &&
                                <>
                                    <img className={'iconCloseChat'} alt={''} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        //setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <video style={{width: '100%', height: '90%'}} src={filePreview} controls/>
                                </>
                            }
                            {typeData === 'document' &&
                                <>
                                    <img className={'iconCloseChat'} alt={''} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        //setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <iframe title={'preview'} src={filePreview+'#toolbar=0'} width={'100%'} height={'90%'}/>
                                </>
                            }
                        </div>
                    }


                    {(!fileSended && showWriter)&&
                        <Writer
                            //value={message}
                            //changeValue={value => setMessage(value)}
                            canWrite={isWriting}
                            writing={value => userIsWriting(value)}
                            pressSender={(value) => sendNewMessage(value)}
                            sendMessage={(value) => sendNewMessage(value)}
                        />
                    }

                </div>
            </div>

        </div>
        </>
    )
}

export default ChatIa;
