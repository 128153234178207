import React, {useEffect, useState} from 'react';
import './sessions.css'

import ScheduleComponent from '../../components/scheduleSession/scheduleSession.component';
import ScheduledSession from '../../components/scheduledSession/scheduledSession.component';
import HistoryItem from '../../components/historyItem/historyItem.component';
import {useNavigate} from 'react-router-dom';


import { getScheduleSessionsClose, getScheduleSession } from '../../services/data.service';

import * as moment from 'moment';
import {useTranslation} from "react-i18next";

const Sessions = () => {
    const navigate = useNavigate();
    //const [user, setUser] = useState()
    const [sessions, setSessions] = useState([]);
    const [closeSessions, setCloseSessions] = useState([]);


    const {t} = useTranslation();

    moment.lang('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
    }
    );

    useEffect(() => {
        //setUser(JSON.parse(localStorage.getItem('USER')).user)

        getActiveSession(JSON.parse(localStorage.getItem('USER')).user.id)

        getScheduleSessionsClose(JSON.parse(localStorage.getItem('USER')).user.id).then(res => {
            console.log('this is a sesions close', res.data)
            setCloseSessions(res.data.sort((a,b) => new Date(b.start_date) - new Date(a.start_date)))
        }).catch(err => {
            console.log('error get close sessions', err)
        })
    }, [])

    const getActiveSession = (userId) => {
        getScheduleSession(userId).then(res => {
            console.log('estas son las sesione', res.data)
            setSessions(res.data)
        }).catch(err => {
            console.log('error get session', err)
        })
    }

    return (
        <div className={'containerSessions'}>
            <p className='titleSessions'>{t('sessions')}</p>

            <p className='subtitleSessions'>Próximas sesiones con tu Coach</p>
            {sessions.length > 0 ?
                <ScheduledSession data={sessions[0]}/>
                :
                <ScheduleComponent press={() => navigate('/coaches')}/>
            }
            <p className='subtitleSessions'>Sesiones realizadas con tu Coach</p>

            <div className={'containerItemSessions'}>
            {closeSessions.map(session => {
                return (
                    <HistoryItem
                        image={session.coach_image}
                        name={session.coach_name}
                        date={session.start_date}
                        session
                        //press={() => navigate('/chat', {state: {idChat: chat.id, coach: chat.channelUsers.coach, writer: false}})}
                    />
                )
            })
            }
            </div>

            
        </div>
    )
}

export default Sessions;